import Head from "next/head";

export default function Header() {
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
      <meta content="ProFile Aesthetic Management" name="application-name" />
      <meta content="yes" name="apple-mobile-web-app-capable" />
      <meta content="default" name="apple-mobile-web-app-status-bar-style" />
      <meta
        content="ProFile Aesthetic Management"
        name="apple-mobile-web-app-title"
      />
      <meta content="Keywords" name="keywords" />
      <meta
        content="App for Aesthetic Patient Management, Procedure Tracking, Scheduling Appointments, Taking Payments"
        name="description"
      />
      <meta content="telephone=no" name="format-detection" />
      <meta content="yes" name="mobile-web-app-capable" />
      <meta content="#FFFFFF" name="theme-color" />
      <title>Profile App</title>
      <meta content="summary" name="twitter:card" />
      <meta
        content="https://app.profileaestheticmanagement.com"
        name="twitter:url"
      />
      <meta content="ProFile Aesthetic Management" name="twitter:title" />
      <meta
        content="App for Aesthetic Patient Management, Procedure Tracking, Scheduling Appointments, Taking Payments"
        name="twitter:description"
      />
      <meta
        content="https://app.profileaestheticmanagement.com/profile_logo.png"
        name="twitter:image"
      />
      <meta content="website" property="og:type" />
      <meta content="ProFile Aesthetic Management" property="og:title" />
      <meta
        content="App for Aesthetic Patient Management, Procedure Tracking, Scheduling Appointments, Taking Payments"
        property="og:description"
      />
      <meta content="ProFile Aesthetic Management" property="og:site_name" />
      <meta
        content="https://app.profileaestheticmanagement.com"
        property="og:url"
      />
      <meta
        content="https://app.profileaestheticmanagement.com/profile_logo.png"
        property="og:image"
      />
      <link
        href="/icons/icon-180x180.png"
        rel="apple-touch-icon"
        sizes="180x180"
      />
      <link color="#ffffff" href="/icons/icon-mask.png" rel="mask-icon" />
      <link href="/manifest.json" rel="manifest" />
      <link href="/favicon.ico" rel="shortcut icon" />
    </Head>
  );
}
