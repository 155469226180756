import { createContext, useContext, useState } from "react";
import useSWR from "swr";

interface IAPIContext {
  url: string;
  superAdminUrl: string;
}

export const ApiContext = createContext<IAPIContext>({
  url: "",
  superAdminUrl: "",
});

type Methods = "POST" | "GET" | "PUT" | "DELETE" | "PATCH";

type Message = object | string | null;

type Headers = { [key: string]: string | number | string[] };

type ContentType =
  | "application/json"
  | "application/x-www-form-urlencoded"
  | "multipart/form-data"
  | "image/jpeg"
  | "text/plain"
  | "text/html";

/**
 * typedef {object} ApiResponse
 * @property {boolean} ok - true if the status code is in the 200 range
 * @property {number} status - HTTP status code
 * @template T - type of the response data
 * @property {response} T - The response body
 */
export interface Result<T extends string | object | null | boolean> {
  status: number;
  ok: boolean;
  response: T;
  responseHeaders?: Headers;
}

/**
 * typedef {object} HookResponse
 * @property {boolean} ok - true if the status code is in the 200 range
 * @property {number} status - HTTP status code
 * @property {string} translatedMessage - Optional translated message to present to users
 * @template T - type of the response data
 * @property {response} T - The response body
 */
export interface HookResult<T extends string | object | null | boolean>
  extends Result<T> {
  translatedMessage?: string;
}

export interface ResultWithHeader<T extends string | object | null | boolean> {
  status: number;
  ok: boolean;
  response: T;
  headers?: Headers;
}

interface CallApiOptions<M = null> {
  token?: string;
  body?: M;
  headers?: Headers;
  contentType?: ContentType;
  queryParams?: { [key: string]: string };
  nextApi?: boolean;
}

export class HttpError<R> extends Error {
  info: R | undefined;
  status: number | undefined;
  constructor() {
    super("HttpError");
  }
}

/**
 * Generic function to make an API call. If contentType is not specified, assumes application/json
 * and serializes the object to JSON. Only accepts text and JSON responses.
 * @template D The type of the data returned by the API
 * @template E The type of the error returned by the API
 * @template M The body of the API request. Can be a string or an object
 * @param url The URL to make the request to.
 * @param method The HTTP method to use.
 * @param token Optional token inserted into the request header prefixed with "Bearer ".
 * @param {string | object} body Optional body to send with the request.
 * @param headers Optional object of additional headers.
 * @param contentType Optional content type to send with the request. Defaults to application/json.
 * @param queryParams Optional object of query parameters to append to the URL.
 * @param nextApi Optional boolean to indicate if the request is to a NextJS API. These are same origin requests and do not have a baseURL. Defaults to false.
 * @returns {ApiResponse} A promise that resolves to an object containing the response body, status
 * code, and a boolean ok which is true if the status code is in the 200 range. Will only reject
 * due to network errors and not on non 200 status codes.
 */
export async function callApi<
  D extends string | object | null | boolean,
  E extends string | object | null | boolean,
  M extends Message = any
>(
  url: string,
  method: Methods,
  {
    token,
    body,
    headers,
    contentType = "application/json",
    queryParams,
    nextApi = false,
  }: CallApiOptions<M> = {}
): Promise<Result<D | E>> {
  try {
    let finalUrl: string;
    if (!nextApi) {
      const callUrl = new URL(url);
      if (queryParams) {
        Object.entries(queryParams).map(([key, value]) =>
          callUrl.searchParams.append(key, value)
        );
      }
      finalUrl = `${callUrl.toString()}${queryParams ? "/" : ""}`;
    } else {
      finalUrl = url;
    }

    const response = await fetch(finalUrl, {
      method,
      // header Content-type: multipart/form-data has a problem with FormData: https://muffinman.io/blog/uploading-files-using-fetch-multipart-form-data/
      headers: {
        ...(contentType === "multipart/form-data"
          ? {}
          : {
              "Content-type": contentType,
            }),
        ...(token && { Authorization: `Bearer ${token}` }),
        ...headers,
      },
      body:
        contentType === "application/json"
          ? JSON.stringify(body)
          : (body as BodyInit),
    });

    return {
      status: response.status,
      ok: response.ok,
      response: response.headers
        .get("Content-type")
        ?.includes("application/json")
        ? await response.json()
        : await response.text(),
    } as Result<D | E>;
  } catch (error) {
    //Fetch on rejects when network https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#checking_that_the_fetch_was_successful
    //TODO: Add proper logging. Figure out what to do with these errors.
    console.error(error);
    throw error;
  }
}

/**
 * Fetch function that GETs a URL which returns a blob and returns the objectURL of the blob.
 * @param path URL to GET which returns a blob
 * @param token Optional token inserted into the request header prefixed with "Bearer ".
 * @returns {Promise<string>} The objectURL of the blob
 */
export async function useFetchProfileBlobs<E extends object>(
  baseUrl: string,
  path: string,
  token?: string
): Promise<string> {
  try {
    const response = await fetch(`${baseUrl}/${path}`, {
      method: "GET",
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
      },
    });

    if (!response.ok) {
      const error = new HttpError<E>();
      // Assuming error is application/json (which all Profile Server errors are so far)
      error.info = response.json() as E;
      error.status = response.status;
      throw error;
    }

    const blob = await response.blob();
    return URL.createObjectURL(blob);
  } catch (error) {
    console.error(error);
    throw error;
  }
}

/**
 * React hook to make an API call. Automatically handles requests and responses of either JSON or text.
 * @template D The type of the data returned by the API
 * @template E The type of the error returned by the API
 * @template M The body of the API request. Can be a string or an object
 * @returns An object with the following properties: data, error, loading, call. Use call to
 * initiate the API call. Data and error are null until the API call is complete. Loading is true
 * when the API call is in progress. Data is only set if the API response is in the 200 range.
 * Else sets error.
 */
export function useApi<
  D extends string | object | null | boolean,
  E extends string | object | null | boolean,
  M extends Message = null
>(): {
  data: Result<D> | undefined;
  error: Result<E> | undefined;
  loading: boolean;
  call: (
    url: string,
    method: Methods,
    { token, body, headers }: CallApiOptions<M>
  ) => Promise<Result<D | E>>;
} {
  {
    const [data, setData] = useState<Result<D>>();
    const [error, setError] = useState<Result<E>>();
    const [loading, setLoading] = useState(false);

    /**
     *
     * @param url The URL to make the request to.
     * @param method The HTTP method to use.
     * @param token Optional token inserted into the request header prefixed with "Bearer ".
     * @param {string | object} body Optional body to send with the request.
     * @returns {ApiResponse} A promise that resolves to an object containing the response body,
     * status code, and a boolean ok which is true if the status code is in the 200 range. Will only
     * reject due to network errors and not on non 200 status codes.
     */
    const call = async (
      url: string,
      method: Methods,
      callApiParams: CallApiOptions<M>
    ): Promise<Result<D | E>> => {
      setLoading(true);
      try {
        const res = await callApi<D, E, M>(url, method, callApiParams);
        setLoading(false);

        if (res.ok) {
          setData(res as Result<D>);
          setError(undefined);
        } else {
          setError(res as Result<E>);
          setData(undefined);
        }
        return res;
      } catch (error) {
        // TODO: Incorrect typecasting. Return known error shape.
        setLoading(false);
        setError(error as Result<E>);
        return error as Result<E>;
      }
    };
    return { data, error, loading, call } as const;
  }
}

/**
 * Factory function that creates a react hook to call the Profile API
 * @template D The type of the data returned by the API
 * @template E The type of the error returned by the API
 * @template M The body of the API request. Can be a string or an object
 * @param url The url of the endpoint sans the base url (e.g. /forms instead of
 * NEXT_PUBLIC_PROFILE_APP_URL/forms)
 * @param method The HTTP method to use. If using a GET, prefer useSWR instead
 * @param errorCallback Optional callback to run when a non 200 status code is returned. The
 * callback is expected to translate API error to a presentable string for the user. Sets
 * translatedMessage field on the error object.
 * @returns A hook with the following return object: {data, error, loading, call}. Use call to
 * initiate the API call. Data and error are null until the API call is complete. Loading is true
 * when the API call is in progress. Data is only set if the API response is in the 200 range.
 * Else sets error.
 */
export function createProfileApiHook<
  D extends string | object | null | boolean,
  E extends string | object | null | boolean,
  M extends Message = null
>(
  url: string,
  method: Methods,
  {
    headers: defaultHeaders,
    errorCallback,
  }: {
    headers?: Headers;
    errorCallback?: (error: { status: number; response: D | E }) => string;
  } = {}
) {
  /**
   * React hook to make an API call. Automatically handles requests and responses of either JSON or text.
   * @template D The type of the data returned by the API
   * @template E The type of the error returned by the API
   * @template M The body of the API request. Can be a string or an object
   * @returns An object with the following properties: data, error, loading, call. Call is an async
   * function to initiate the API call. Data and error are undefined until the API call is complete
   * Loading is true when the API call is in progress.
   */
  return (): {
    data: HookResult<D> | undefined;
    error: HookResult<E> | undefined;
    loading: boolean;
    call: (callApiOptions?: CallApiOptions<M>) => Promise<HookResult<D | E>>;
  } => {
    const [data, setData] = useState<Result<D>>();
    const [error, setError] = useState<Result<E>>();
    const [loading, setLoading] = useState(false);
    const { url: baseUrl } = useContext(ApiContext);

    /**
     * Function that calls the profile Api
     * @param token Optional token inserted into the request header prefixed with "Bearer ".
     * @param {string | object} body Optional body to send with the request.
     * @returns {ApiResponse} A promise that resolves to an object containing the response body,
     * status code, and a boolean ok which is true if the status code is in the 200 range. Will
     * only reject due to network errors and not on non 200 status codes.
     */
    const call = async ({
      token,
      body,
      headers = defaultHeaders,
      contentType,
      queryParams,
    }: CallApiOptions<M> = {}): Promise<HookResult<D | E>> => {
      setLoading(true);
      try {
        const res = await callApi<D, E, M>(`${baseUrl}${url}`, method, {
          token,
          body,
          headers,
          contentType,
          queryParams,
        });
        setLoading(false);
        if (res.ok) {
          setData(res as HookResult<D>);
        } else {
          const errorRes: HookResult<E> = { ...(res as Result<E>) };
          if (errorCallback) {
            errorRes.translatedMessage = errorCallback({
              status: res.status,
              response: res.response,
            });
          }
          setError(errorRes);
        }
        return res;
      } catch (error) {
        setLoading(false);
        // TODO: Incorrect typecasting. Return known error shape.
        setError(error as HookResult<E>);
        return error as HookResult<E>;
      }
    };
    return { data, error, loading, call } as const;
  };
}

export const useProfileApiData = <
  T extends string | object | null | boolean,
  E = any
>(
  path: string,
  {
    token,
    headers,
    queryParams,
    initialData,
    contentType,
  }: {
    token?: string;
    headers?: Headers;
    queryParams?: { [key: string]: string };
    initialData?: T;
    contentType?: ContentType;
  } = {}
) => {
  const { url } = useContext(ApiContext);

  return useSWR<T, HttpError<E>>(
    token ? [url, path, token] : null,
    async (url, path, token) => {
      const res = await callApi(`${url}${path}`, "GET", {
        token,
        headers,
        contentType,
        queryParams,
      });
      if (!res.ok) {
        const error = new HttpError();
        error.info = res.response;
        error.status = res.status;
        throw error;
      }
      return res.response as T;
    },
    { fallbackData: initialData }
  );
};

/**
 * Factory function that creates a react hook to call the Profile API
 * @template D The type of the data returned by the API
 * @template E The type of the error returned by the API
 * @template M The body of the API request. Can be a string or an object
 * @param url The url of the endpoint sans the base url (e.g. /forms instead of
 * NEXT_PUBLIC_PROFILE_APP_URL/forms)
 * @param method The HTTP method to use. If using a GET, prefer useSWR instead
 * @param errorCallback Optional callback to run when a non 200 status code is returned. The
 * callback is expected to translate API error to a presentable string for the user. Sets
 * translatedMessage field on the error object.
 * @returns A hook with the following return object: {data, error, loading, call}. Use call to
 * initiate the API call. Data and error are null until the API call is complete. Loading is true
 * when the API call is in progress. Data is only set if the API response is in the 200 range.
 * Else sets error.
 */
export function createProfileSuperAdminApiHook<
  D extends string | object | null | boolean,
  E extends string | object | null | boolean,
  M extends Message = null
>(
  url: string,
  method: Methods,
  {
    headers: defaultHeaders,
    errorCallback,
  }: {
    headers?: Headers;
    errorCallback?: (error: { status: number; response: D | E }) => string;
  } = {}
) {
  /**
   * React hook to make an API call. Automatically handles requests and responses of either JSON or text.
   * @template D The type of the data returned by the API
   * @template E The type of the error returned by the API
   * @template M The body of the API request. Can be a string or an object
   * @returns An object with the following properties: data, error, loading, call. Call is an async
   * function to initiate the API call. Data and error are undefined until the API call is complete
   * Loading is true when the API call is in progress.
   */
  return (): {
    data: HookResult<D> | undefined;
    error: HookResult<E> | undefined;
    loading: boolean;
    call: (callApiOptions?: CallApiOptions<M>) => Promise<HookResult<D | E>>;
  } => {
    const [data, setData] = useState<Result<D>>();
    const [error, setError] = useState<Result<E>>();
    const [loading, setLoading] = useState(false);
    const { superAdminUrl: baseUrl } = useContext(ApiContext);

    /**
     * Function that calls the profile Api
     * @param token Optional token inserted into the request header prefixed with "Bearer ".
     * @param {string | object} body Optional body to send with the request.
     * @returns {ApiResponse} A promise that resolves to an object containing the response body,
     * status code, and a boolean ok which is true if the status code is in the 200 range. Will
     * only reject due to network errors and not on non 200 status codes.
     */
    const call = async ({
      token,
      body,
      headers = defaultHeaders,
      contentType,
      queryParams,
    }: CallApiOptions<M> = {}): Promise<HookResult<D | E>> => {
      setLoading(true);
      try {
        const res = await callApi<D, E, M>(`${baseUrl}${url}`, method, {
          token,
          body,
          headers,
          contentType,
          queryParams,
        });
        setLoading(false);
        if (res.ok) {
          setData(res as HookResult<D>);
        } else {
          const errorRes: HookResult<E> = { ...(res as Result<E>) };
          if (errorCallback) {
            errorRes.translatedMessage = errorCallback({
              status: res.status,
              response: res.response,
            });
          }
          setError(errorRes);
        }
        return res;
      } catch (error) {
        setLoading(false);
        // TODO: Incorrect typecasting. Return known error shape.
        setError(error as HookResult<E>);
        return error as HookResult<E>;
      }
    };
    return { data, error, loading, call } as const;
  };
}

export const useProfileSuperAdminApiData = <
  T extends string | object | null | boolean,
  E = any
>(
  path: string,
  {
    token,
    headers,
    initialData,
    contentType,
  }: {
    token?: string;
    headers?: Headers;
    initialData?: T;
    contentType?: ContentType;
  } = {}
) => {
  const { superAdminUrl } = useContext(ApiContext);

  return useSWR<T, HttpError<E>>(
    token ? [superAdminUrl, path, token] : null,
    async (superAdminUrl, path, token) => {
      const res = await callApi(`${superAdminUrl}${path}`, "GET", {
        token,
        headers,
        contentType,
      });
      if (!res.ok) {
        const error = new HttpError();
        error.info = res.response;
        error.status = res.status;
        throw error;
      }
      return res.response as T;
    },
    { fallbackData: initialData }
  );
};

export async function profileFetcher<
  D extends string | object | null | boolean,
  E extends string | object | null | boolean
>(path: string, options?: CallApiOptions) {
  const res = await callApi<D, E>(
    `${process.env.NEXT_PUBLIC_PROFILE_APP_URL}${path}`,
    "GET",
    options
  );
  if (!res.ok) {
    const error = new HttpError<E>();
    error.info = res.response as E;
    error.status = res.status;
    throw error;
  }
  return res.response as D;
}

export function createCommonAppointmentApiHook<
  D extends string | object | null | boolean,
  E extends string | object | null | boolean,
  M extends Message
>({
  patientId,
  providerId,
  errorCallback,
}: {
  patientId: string;
  providerId: string;
  errorCallback?: (error: { status: number; response: D | E }) => string;
}) {
  /**
   * React hook to make an API call. Automatically handles requests and responses of either JSON or text.
   * @template D The type of the data returned by the API
   * @template E The type of the error returned by the API
   * @template M The body of the API request. Can be a string or an object
   * @returns An object with the following properties: data, error, loading, call. Call is an async
   * function to initiate the API call. Data and error are undefined until the API call is complete
   * Loading is true when the API call is in progress.
   */
  return (): {
    patientData: HookResult<D> | undefined;
    providerData: HookResult<D> | undefined;
    error: HookResult<E> | undefined;
    loading: boolean;
    call: ({
      token,
      body,
      queryParams,
    }: {
      token?: string;
      body?: M;
      queryParams?: { [key: string]: string };
    }) => Promise<HookResult<D | E>>;
  } => {
    const [patientData, setPatientData] = useState<Result<D>>();
    const [providerData, setProviderData] = useState<Result<D>>();
    const [error, setError] = useState<Result<E>>();
    const [loading, setLoading] = useState(false);
    const { url: baseUrl } = useContext(ApiContext);

    /**
     * Function that calls the profile Api
     * @param token Optional token inserted into the request header prefixed with "Bearer ".
     * @param {string | object} body Optional body to send with the request.
     * @returns {ApiResponse} A promise that resolves to an object containing the response body,
     * status code, and a boolean ok which is true if the status code is in the 200 range. Will
     * only reject due to network errors and not on non 200 status codes.
     */
    const call = async ({
      token,
      body,
    }: {
      token?: string;
      body?: M;
    }): Promise<HookResult<D | E>> => {
      setLoading(true);
      try {
        const patientUrl = new URL(
          `${baseUrl}/appointments/patient/${patientId}`
        );

        const providerUrl = new URL(
          `${baseUrl}/appointments/provider/${providerId}`
        );

        const patientRes = await callApi<D, E, M>(
          patientUrl.toString(),
          "GET",
          {
            token,
            body,
          }
        );

        if (patientRes.ok) {
          setPatientData(patientRes as HookResult<D>);
        } else {
          const errorRes: HookResult<E> = { ...(patientRes as Result<E>) };
          if (errorCallback) {
            errorRes.translatedMessage = errorCallback({
              status: patientRes.status,
              response: patientRes.response,
            });
          }
          setError(errorRes);
        }

        const providerRes = await callApi<D, E, M>(
          providerUrl.toString(),
          "GET",
          {
            token,
            body,
          }
        );

        if (providerRes.ok) {
          setProviderData(providerRes as HookResult<D>);
        } else {
          const errorRes: HookResult<E> = { ...(providerRes as Result<E>) };
          if (errorCallback) {
            errorRes.translatedMessage = errorCallback({
              status: providerRes.status,
              response: providerRes.response,
            });
          }
          setError(errorRes);
        }

        setLoading(false);

        return providerRes;
      } catch (error) {
        setLoading(false);
        // TODO: Incorrect typecasting. Return known error shape.
        setError(error as HookResult<E>);
        return error as HookResult<E>;
      }
    };

    return { patientData, providerData, error, loading, call } as const;
  };
}

/**
 * Can be used to asynchronously pause a function. Useful for testing slow API calls
 * @param ms time in ms
 * @returns Promise that resolves after ms milliseconds
 */
export async function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
