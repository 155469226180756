import { NextPage } from "next";
import { AppProps } from "next/app";
import { SessionProvider } from "next-auth/react";

import BaseLayout from "../components/layouts/BaseLayout";
import { Toaster } from "../components/shadcn/toaster";
import { ApiContext } from "../utils/apiHelpers";

import "../styles/globals.css";
import "../styles/helpers.css";

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout =
    Component.getLayout || ((page) => <BaseLayout>{page}</BaseLayout>);

  return (
    <SessionProvider session={pageProps.session}>
      <ApiContext.Provider
        value={{
          url: process.env.NEXT_PUBLIC_PROFILE_APP_URL,
          superAdminUrl: process.env.NEXT_PUBLIC_PROFILE_SUPER_ADMIN_URL,
        }}
      >
        {getLayout(<Component {...pageProps} />)}
        <Toaster />
      </ApiContext.Provider>
    </SessionProvider>
  );
}

export default MyApp;
